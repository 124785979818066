<template>
  <div class="menu-sub-box">
    <div class="menu-item first" v-for="(item) in tree" :key="item.sectionId">
      <!-- 一级 -->
      <div class="first-title" @click="onActive(item)" :class="{'no-use' : item.lock==1 && item.fileId}">
        <p class="name1" :class="{'p-hover' : item.sectionId == sectionId}">{{item.sectionName}}</p>
        <img class="tree_icon" v-if="item.lock==1 && item.fileId" src="@/assets/image/selected/lock-998.png" />
        <img class="tree_icon" v-else-if="item.sectionId == sectionId" src="@/assets/image/selected/lock-997.png" />
      </div>
      <div class="menu-item" v-for="(item) in item.childList" :key="item.sectionId">
        <!-- 二级 -->
        <div class="first-title" @click="onActive(item)" :class="{'no-use' : item.lock==1 && item.fileId}">
          <p class="name2" :class="{'p-hover' : item.sectionId == sectionId}">{{item.sectionName}}</p>
          <img class="tree_icon" v-if="item.lock==1 && item.fileId" src="@/assets/image/selected/lock-998.png" />
          <img class="tree_icon" v-else-if="item.sectionId == sectionId" src="@/assets/image/selected/lock-997.png" />
        </div>
        <div class="menu-item" v-for="(item) in item.childList" :key="item.sectionId">
          <!-- 三级 -->
          <div class="first-title" @click="onActive(item)" :class="{'no-use' : item.lock==1 && item.fileId}">
            <p class="name3" :class="{'p-hover' : item.sectionId == sectionId}">{{item.sectionName}}</p>
            <img class="tree_icon" v-if="item.lock==1 && item.fileId" src="@/assets/image/selected/lock-998.png" />
            <img class="tree_icon" v-else-if="item.sectionId == sectionId" src="@/assets/image/selected/lock-997.png" />
          </div>
          <div class="menu-item" v-for="(item) in item.childList" :key="item.sectionId">
            <!-- 四级 -->
            <div class="first-title" @click="onActive(item)" :class="{'no-use' : item.lock==1 && item.fileId}">
              <p class="name3" :class="{'p-hover' : item.sectionId == sectionId}">{{item.sectionName}}</p>
              <img class="tree_icon" v-if="item.lock==1 && item.fileId" src="@/assets/image/selected/lock-998.png" />
              <img class="tree_icon" v-else-if="item.sectionId == sectionId" src="@/assets/image/selected/lock-997.png" />
            </div>
            <div class="menu-item" v-for="(item) in item.childList" :key="item.sectionId">
              <!-- 五级 -->
              <div class="first-title" @click="onActive(item)" :class="{'no-use' : item.lock==1 && item.fileId}">
                <p class="name3" :class="{'p-hover' : item.sectionId == sectionId}">{{item.sectionName}}</p>
                <img class="tree_icon" v-if="item.lock==1 && item.fileId" src="@/assets/image/selected/lock-998.png" />
                <img class="tree_icon" v-else-if="item.sectionId == sectionId" src="@/assets/image/selected/lock-997.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    tree: {
			type: Array,
			default: ()=>[]
    },
    // 选中章节id
    sectionId: {
      type: Number,
			default: null
    },
  },
  // 数据对象
  data () {
    return {
      isHave: 1
    }
  },
  // 事件处理器
  methods: {
    onActive(item){
      // 有视频时并且已购买
      if(item.fileId && this.isHave==1){
        // 当前章节加锁
        if(item.lock==1 && item.isHaveSelfTest == 1){
          this.$message.error('请先学习上一节课程并通过小节测试');
        } else if(item.lock==1 && item.isHaveSelfTest == 0){
          this.$message.error('请先学习完上一节课程');
        } else {
          // this.activeIndex = item.sectionId + pid
          this.$emit('nodeClick', item.sectionId)
        }
      } else if(item.fileId && this.isHave==0){
        // 当前章节加锁
        // if(item.isFreeTrial==1){
        //   this.activeIndex = item.sectionId + pid
        //   this.$emit('nodeClick', item.sectionId)
        // } else if(item.lock==1) {
        //   this.$message.error('您当前没有购买此课程，请去App端免费试看视频');
        // }
        this.$message.warning('您当前没有购买此课程，请去App端免费试看视频');
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.menu-sub-box{
  margin-top: 16px;
  line-height: 42px;
  font-weight: 500;
  max-height: 95%;
  padding-right: 10px;
  font-family: PingFangMedium;
  overflow-y: scroll;
  .menu-item{
    .first-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      p{
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .p-hover{
        color: #15B7DD;
      }
      .name1{
        font-size: 24px;
      }
      .name2{
        font-size: 24px;
      }
      .name3{
        margin-left: 24px;
        font-size: 20px;
      }
      .tree_icon{
        width: 24px;
        height: 24px;
      }
    }
    .no-use{
      cursor: not-allowed;
    }
  }
  .first{
    margin-bottom: 10px;
  }
}
/* 设置滚动条的样式 */
.menu-sub-box::-webkit-scrollbar {
  width:4px;
}
/* 滚动槽 */
.menu-sub-box::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:10px;
  background:rgba(87, 54, 54, 0.2);
}
/* 滚动条滑块 */
.menu-sub-box::-webkit-scrollbar-thumb {
  border-radius:10px;
  background:rgba(255,255,255,0.21);
  -webkit-box-shadow:inset006pxrgba(255, 255, 255, 0.6);
}
</style>
